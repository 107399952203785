

// export const baseurl='http://localhost:5000'

// export const baseurl='https://trackpaxbackend-f5c921ead279.herokuapp.com'

export const baseurl='https://tracpax-ts-production.up.railway.app'






