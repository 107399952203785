import React, { Component } from 'react'
import Sidebar from './sidebar'
import Setting from './setting'


export const Setting_Page =()=>{


return(
    <div>
        <Sidebar/>

        <Setting/>


    </div>
)

}