import React from 'react';


const Popup = () => {
    return (
        <div className='my-new-popup'>
            <div className="popup" style={{backgroundColor:"#fff"}}>
            <div class="dot-spinner">
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
</div>
<div className='my-old-h2'>
<h2 >Too Many Requests</h2>

                <h2>
                Unable to retrieve data. Please try again in a few minutes.
                    </h2> 
                    <h2>  Thank you for your patience.</h2>
</div>
                
            </div>


        </div>
    );
}

export default Popup;
