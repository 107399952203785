import React, { Component } from 'react'
import Sidebar from './sidebar'
import List1 from './list1'
import Header from './header'
import HeaderInner from './headerinner'


const Dashboard =()=>{


return(



    <div>

<HeaderInner />


    <Sidebar/>

    <List1/>


    </div>
)




}

export default Dashboard